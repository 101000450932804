
@import '../scss/config';
@import '~@material/list/mdc-list';

.mandate-property {
    &__badge {
        color: #000000;

        &.mandate-property__badge--alert {
            .md-badge.md-theme-default {
                background-color: $imagine-orange;
            }
        }
    }

    .mandate-property-alert {
        display: flex;
        align-items: center;
        color: $mdc-theme-primary;

        .material-icons {
            margin-right: .5rem;
        }

        &--is-critical {
            color: $imagine-orange;
        }
    }

    &__infos {
        padding: 8px 8px 2px;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: -8px;
            margin-left: -8px;
        }

        &__info {
            text-align: center;
            flex: 1;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 16px;
            line-height: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.2rem;
            /*height: 56px;*/

            a.mandate-property__infos__info__link,
            a.mandate-property__infos__info__link:hover {
                color: #ffffff;
                text-decoration: underline;
            }
        }
    }

    &__fields {
        display: flex;
        align-items: center;
        padding: .5rem;
        flex-direction: column;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            flex-direction: row;
        }

        .md-field {
            margin: 0 .5rem .5rem;
        }

        .imagine-button {
            max-width: 150px;
        }
    }

    &__actions {
        padding: 1rem;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .imagine-select {
            width: 180px;
            margin: 0;
        }
    }

    &__accounts {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        padding: .5rem;
        overflow: auto;
        flex-direction: column;

        @media (min-width: 1440px) {
            flex-direction: row;
        }
    }

    &__logs {
        &__actors {
            display: flex;
            flex-direction: column;

            @media (min-width: 1440px) {
                flex-direction: row;
            }

            &__actor {
                &--inactive {
                    opacity: .85;
                }

                padding: 0 1rem;
                flex: 1;

                h3 {
                    text-align: center;
                    font-size: 1.2rem;
                    font-weight: normal;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 48px;
                }

                &__nonav {
                    width: 48px;
                }

                &__filter {
                    padding: .5rem;
                    text-align: center;
                    font-size: 1.1rem;
                }

                &__log {
                    .mdc-list-item__graphic {
                        width: 72px;
                    }

                    .mdc-list-item__meta {
                        display: flex;
                    }
                }
            }
        }

        &__empty {
            padding: 2rem;
            text-align: center;
            font-size: 1.25rem;
        }
    }
}
