@import '~normalize.scss/normalize';
@import '~@material/typography/mdc-typography';
@import '~vue-material/dist/vue-material.css';
@import '_config';
@import '~@material/theme/variables';
@import '~vue-material/dist/theme/engine';

@include md-register-theme('default', (primary: $mdc-theme-primary, accent: $mdc-theme-secondary));
@import '~vue-material/dist/theme/all';

:root {
  --md-theme-default-fieldvariant: #{$imagine-error-color};
}

a:not(.md-button):hover {
  text-decoration: none;
}

.md-field {
  background: whitesmoke;
  padding-left: .5rem;

  label {
    top: 15px;
    padding-left: .5rem;
  }
}

.md-ripple {
  z-index: 3;
}

.mdc-dialog-scroll-lock {
  overflow: auto !important;
}

.imagine-icons {
  height: 24px;
  width: 24px;
  display: inline-block;

  &--immopf {
    background: transparent url('../static/immopf.svg') no-repeat center center;
    background-size: 32px;
  }

  &--itool {
    background: transparent url('../static/itool.png') no-repeat center center;
    background-size: 32px;
  }

  &--word {
    background: transparent url('../static/word.png') no-repeat center center;
    background-size: 32px;
  }

  &--vmlive {
    background: transparent url('../static/vmlive.png') no-repeat center center;
    background-size: 32px;
  }

  &--brevo {
    background: transparent url('../static/brevo.svg') no-repeat center center;
    background-size: 32px;
  }

  &--socredo {
    background: transparent url('../static/socredo.svg') no-repeat center center;
    background-size: 32px;
  }
}

a.imagine-link,
a.imagine-link:active,
a.imagine-link:hover,
a.imagine-link:visited {
  color: $mdc-theme-primary;
  color: var(--mdc-theme-primary, $mdc-theme-primary);
  text-decoration: underline;
}

.imagine-secondary-zone {
  background: $mdc-theme-secondary;
  background: var(--mdc-theme-secondary, $mdc-theme-secondary);
  color: #ffffff;
}

.search__filters {
  &__search {
    padding: 6px .5rem;
    height: 60px;
    box-sizing: border-box;

    .md-field {
      margin: 0;
    }

    input {
      background: #ffffff;
      padding: 0 .5rem !important;
    }
  }
}

.imagine-status {
  &--available,
  &--available.mdc-list-item__graphic,
  &--success {
    color: $imagine-green;
  }

  &--option,
  &--option.mdc-list-item__graphic,
  &--danger,
  &--danger.mdc-list-item__graphic {
    color: $imagine-orange;
  }

  &--booked,
  &--booked.mdc-list-item__graphic,
  &--error {
    color: $imagine-error-color;
  }

  &--sold,
  &--sold.mdc-list-item__graphic {
    color: #000000;
  }
}

.imagine-rounded-shape {
  overflow: hidden;
  width: 48px;
  height: 48px;
  border: 1px solid #dddddd;
  border-radius: 24px;
  font-size: 16px;
  text-align: center;

  &.mdc-list-item__graphic {
    background: white;
    width: 48px;
    height: 48px;
  }

  &.material-icons, .material-icons {
    width: 44px;
    height: 44px;
    font-size: 42px;
    padding: 2px;
  }

  img {
    width: 48px;
    height: 48px;
  }
}

.imagine-container {
  max-width: $imagine-container-width;
  margin: 0 auto;
}

/* Form */

.imagine-form {
  .imagine-form__error, .imagine-input .mdc-text-field-helper-line .mdc-text-field-helper-text.imagine-form__error {
    color: $imagine-error-color;
  }

  &__sep {
    border-bottom: 1px dotted rgba(0, 0, 0, .42);
    height: 1px;
    padding: 0;
    margin: 1rem 6rem;
  }

  &__row {
    padding: .5rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    &--limited {
      @media (max-width: map-get($imagine-breakpoints, 'phone')) {
        flex-direction: column;

        .imagine-form__row__field {
          margin-right: 0;
        }
      }
    }

    &--alone {
      .imagine-form__row__field {
        flex: 0 0 50%;
        @media (max-width: map-get($imagine-breakpoints, 'phone')) {
          flex: 1;
        }
      }
    }

    &__field {
      margin-right: .5rem;
      flex: 1;
      width: 100%;

      &:last-child {
        margin-right: 0;
      }

      &--readonly {
        @media (min-width: map-get($imagine-breakpoints, 'phone')) {
          min-height: 48px;
          margin: 4px 0 24px;
        }
        display: flex;
        align-items: center;
      }
    }
  }

  &__actions {
    padding: .5rem;
    text-align: right;
  }
}

.mdc-select .mdc-floating-label.mdc-floating-label--float-above-hack,
.mdc-text-field .mdc-floating-label.mdc-floating-label--float-above-hack {
  transform: translateY(-70%) scale(0.75);
  top: 20px;
}

.mdc-text-field--textarea .mdc-floating-label.mdc-floating-label--float-above-hack {
  transform: translateY(-144%) scale(0.75) !important;
  background: white;
  padding: 0 7px;
}

.md-field.md-theme-default.md-disabled label, .md-field.md-theme-default.md-disabled .md-input, .md-field.md-theme-default.md-disabled .md-textarea {
  color: rgba(0, 0, 0, 0.3);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.3);
}


.imagine-container {
  .imagine-table-container {
    margin-top: 1rem;
  }
}

.imagine-table-container {
  overflow-x: auto;

  .imagine-table {
    min-width: 100%;
    border: 0;
    border-collapse: collapse;
    table-layout: fixed;

    tbody {
      .imagine-table__row {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    &__row {
      &:hover {
        background: rgba(0, 0, 0, 0.04);
      }

      &--disabled {
        opacity: .7;
      }

      &__cell {
        padding: .5rem;
        font-size: .95rem;

        &--danger {
          border: 1px solid $imagine-orange;
        }

        &--header {
          font-weight: bold;
          text-align: left;
        }

        &--number {
          width: 60px;
        }

        &--date {
          width: 120px;
        }

        &--furnished {
          width: 80px;
        }

        &--amount, &--rent {
          text-align: right;
        }

        &--stat {
          text-align: right;
          width: 150px;
        }

        &--text {
          min-width: 200px;
        }

        &--actions {
          white-space: nowrap;
        }
      }
    }
  }
}

/* Animations */

.side-leave-active, .side-enter-active {
  transition: .15s;
  transition-timing-function: ease;
}

.side-enter, .side-leave-to {
  transform: translate(-100%, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.15s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active,
.fade-leave-to {
  opacity: 0
}

.slideY-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.slideY-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slideY-enter-to, .slideY-leave {
  max-height: 1000px;
  overflow: hidden;
}

.slideY-enter, .slideY-leave-to {
  max-height: 0;
  overflow: hidden;
}

@keyframes blink {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1.0;
  }
}

@-webkit-keyframes blink {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1.0;
  }
}

.blink {
  animation: blink 1000ms infinite;
}
