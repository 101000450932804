
@import '../../../scss/config';

.account {
    margin: .5rem auto;
    min-width: 300px;
    max-width: 1400px;
    padding: 0 .5rem;

    .imagine-table__row__cell--account-label {
        max-width: 250px;
    }

    &--lettering-success {
        .account__entry--lettering-debit .account__entry__debit,
        .account__entry--lettering-debit .account__entry__credit,
        .account__entry--lettering-credit .account__entry__debit,
        .account__entry--lettering-credit .account__entry__credit {
            border-color: $imagine-green;
        }
    }

    &--lettering-current {
        .account__entry--lettering-debit .account__entry__debit,
        .account__entry--lettering-debit .account__entry__credit,
        .account__entry--lettering-credit .account__entry__debit,
        .account__entry--lettering-credit .account__entry__credit {
            border-color: $imagine-orange;
        }
    }

    &--lettering-error {
        .account__entry--lettering-debit .account__entry__debit,
        .account__entry--lettering-debit .account__entry__credit,
        .account__entry--lettering-credit .account__entry__debit,
        .account__entry--lettering-credit .account__entry__credit {
            border-color: $imagine-error-color;
        }
    }

    &__entry {
        &--first {
            .account__entry__credit,
            .account__entry__debit {
                border-top: 1px solid #000000 !important;
            }
        }

        &--editable {
            cursor: pointer;
        }

        &__debit {
            border-right: 1px solid #000000;
        }

        &--lettering-debit {
            .account__entry__debit {
                border-width: 1px;
                border-style: solid;
            }
        }

        &--lettering-credit {
            .account__entry__credit {
                border-width: 1px;
                border-style: solid;
            }

            .account__entry__debit {
                border-right-width: 0;
            }
        }
    }

    &__total {
        &__header {
            font-weight: bold;
            text-align: right;
        }

        &__debit {
            border-right: 1px solid #000000;
        }

        &__debit, &__credit {
            font-weight: bold;
            border-top: 1px solid #000000;
        }
    }

    &__letters-action {
        font-size: .8rem;
        font-weight: normal;
        padding: 2px;
        width: 100px;

        &--disabled {
            color: grey;
        }
    }

    &__title {
        text-align: center;
        font-size: 1.2rem;
        font-weight: normal;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__nonav {
            width: 48px;
        }

        &__balance {
            display: flex;
            align-items: center;
            min-height: 48px;
        }
    }

    &__filters {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        @media (max-width: map-get($imagine-breakpoints, 'phone')) {
            flex-direction: column;
        }

        &__title {
            color: rgba(0, 0, 0, 0.54);
            font-size: .8rem;
            padding: 0 .5rem;
            margin-bottom: -3px;
        }

        &__group {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &__filter {
                margin-right: .5rem;
                margin-left: 0;

                &--lettering {
                    width: 190px;
                }

                &--balance {
                    display: flex;
                    align-items: center;

                    span {
                        margin: 0 .5em;
                    }
                }

                .imagine-datepicker, .imagine-select {
                    margin-bottom: 0;
                }
            }

            &--dates {
                width: 400px;

                .account__filters__group__filter {
                    margin-right: 0;
                }
            }

            &--amounts {
                justify-content: flex-end;

                .account__filters__group__filter {
                    margin-right: 0;
                    margin-left: .5rem;
                }
            }
        }
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}
