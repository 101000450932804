
.mandates-report {
    &__line {
        cursor: pointer;
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }

    .imagine-table__row__cell--money {
        text-align: right;
    }
}
