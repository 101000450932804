
.publishing {
    .imagine-layout__header {
        .publishing__actions-sep {
            border-right: 1px solid white;
            height: 24px;
            margin: 0 .5rem;
        }

        .mdc-icon-button:disabled {
          opacity: .45;
        }
    }

    &__disclaimer {
        padding: 1rem;
        font-style: italic;
        margin: 0;
    }

    &__header {
        padding: .5rem 16px .5rem 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__select {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .md-menu {
                margin-left: -10px;
            }

            &__menu {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__count {
                padding: 0 .5rem;
            }
        }

    }
}
