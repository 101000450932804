
@import '../scss/config';
@import '~@material/list/mdc-list';

.mandate-properties {
    &__filters {
        padding: 8px 8px 2px;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: -8px;
            margin-left: -8px;
        }

        &__filter {
            flex: 1;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 56px;
            cursor: pointer;

            &--bordered {
                border: 1px dotted #ffffff;
                padding: .5rem;
                @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
                    flex: unset;
                    width: 150px;
                }
            }

            .md-field {
                margin: 0;
            }
        }
    }

    &__count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        &.mandate-properties__count--filtered {
            .mandate-properties__count__count {
                font-weight: 500;
            }
        }
    }
}

.tenants {
    &__tenant {
        cursor: pointer;
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}
